import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  getFormById,
  getItemsfromLocalStorage,
  handleInitialStateStorage,
  loadReCaptcha,
  sendCustomGtagEvent,
  submitForm,
} from "../util/functions";
import useLocationSearch from "hooks/useLocationSearch";
import FormSelect from "../FormSelect";
import FormInput from "../FormInput";
import { styled } from "linaria/react";
import {
  LandingPageButtonV3,
  LandingPageButtonV4,
} from "components/rhombus-UI/theme/buttons";
import FormLoader from "components/loaders/FormLoader";
import { TextContainer } from "components/rhombus-UI/theme/containers";
import { MainParagraph } from "components/rhombus-UI/theme/typography";
import { getZiContact, sendPartialLeadToBigQuery } from "./functions";

import { getCookie } from "components/page/util/cookie";
import { format } from "date-fns";

const FormWrapper = styled.div`
  width: 100%;
  max-width: ${props => props.maxWidth};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 2.5rem;
`;

const FormTitle = styled.h2`
  color: var(--blue-800);
  font-size: ${props => (props.fancy ? "40px" : "32px")};
  background: ${props => (props.fancy ? "var(--text-gradient)" : "unset")};
  background-clip: ${props => (props.fancy ? "text" : "unset")};
  -webkit-background-clip: ${props => (props.fancy ? "text" : "unset")};
  -webkit-text-fill-color: ${props => (props.fancy ? "transparent" : "unset")};
  line-height: 116.1%;
  font-weight: ${props => (props.fancy ? 800 : 900)};
  @media (max-width: 600px) {
    font-size: 24px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
  text-align: left;
  width: 100%;

  > div {
    width: calc(50% - 1.25rem);
    flex-grow: 1;
  }
  > div:nth-of-type(3) {
    width: 100%;
  }
`;

const FormBackground = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${props => (props.show ? "2.5rem" : "0")};
  border-radius: 0.75rem;
  gap: 1.25rem;
  background: hsl(
    from var(--white) h s l / ${props => (props.show ? "65%" : "0%")}
  );
  position: relative;
  width: ${props => (props.show ? "591px" : "550px")};
  max-width: 100%;
`;

export const ZoomInfoForm = ({
  title,
  subTitle,
  lastStepTitle,
  lastStepText,
  formId,
  successTitle = "Thank You",
  successMessage = "Someone one from Rhombus will reach out soon.",
  setOnFormCompleteBoolean = null,
  renderDisclaimer = null,
  useFancyTitle = false,
  maxFormWidth = "550px",
  useButtonv2 = false,
  useFormBackground = false,
}) => {
  const { parameters } = useLocationSearch();
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [formState, setFormState] = useState();
  const [validationState, setValidationState] = useState();
  const [fields, setFields] = useState([]);
  const [showFinalStep, setShowFinalStep] = useState(false);
  const [ziOutputFields, setZiOutputFields] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [userInteractedWithForm, setUserInteractedWithForm] = useState(false);
  const ButtonComponent = useButtonv2
    ? LandingPageButtonV4
    : LandingPageButtonV3;

  const formRef = useRef(null);

  useEffect(() => {
    if (userInteractedWithForm && typeof posthog !== undefined) {
      const currentUrl = window.location.href;
      posthog.capture("form_start", "$pageview", {
        $current_url: currentUrl,
      });
    }
  }, [userInteractedWithForm]);

  const handleFocus = () => {
    if (!userInteractedWithForm) {
      setUserInteractedWithForm(true);
    }
  };

  useEffect(() => {
    const handleFormMount = async () => {
      if (!formId) return;
      loadReCaptcha();
      let defaultState = {};
      let defaultValidation = {};

      const form = await getFormById(formId);
      const fields = form.formFieldGroups.flatMap(group =>
        group.fields.map(field => field)
      );

      fields.forEach(field => {
        handleInitialStateStorage(field, parameters);
        defaultState = {
          ...defaultState,
          [field.name]: {
            value: getItemsfromLocalStorage(field.name),
            objectTypeId: "0-1",
          },
        };

        defaultValidation = {
          ...defaultValidation,
          [field.name]: {
            valid: "",
          },
        };
      });

      setFields(fields);
      setFormState(defaultState);
      setValidationState(defaultValidation);
      setLoading(false);
    };

    handleFormMount();
  }, [formId]);

  const fieldsToRender = useMemo(() => {
    const fieldNamesToRender = showFinalStep
      ? ziOutputFields
      : ["firstname", "lastname", "email", "phone", "numberofemployees2"];

    return fields.filter(item => {
      return fieldNamesToRender.includes(item.name);
    });
  }, [fields, showFinalStep, ziOutputFields]);

  const renderFields = field => {
    if (field.hidden) {
      return <input type="hidden" key={field.name} name={field.name} />;
    } else return renderFieldComponent(field.fieldType, field);
  };

  const renderFieldComponent = (type, field) => {
    const commonProps = {
      field: field,
      formState: formState,
      setFormState: setFormState,
    };

    const componentMap = {
      select: (
        <FormSelect
          {...commonProps}
          filled
          key={field.name}
          useDependentState={ziOutputFields.includes("country")}
        />
      ),
    };

    return (
      componentMap[type] || (
        <FormInput
          field={field}
          type={type}
          formState={formState}
          setFormState={setFormState}
          validationState={validationState}
          setValidationState={setValidationState}
          key={field.name}
          filled
        />
      )
    );
  };

  const handleSendDataToBigQuery = () => {
    const created_at = format(new Date(), "MM/dd/yyyy hh:mm:ss a");
    const utm_keyword = localStorage.getItem("utm_keyword");
    const utm_adgroup = localStorage.getItem("utm_adgroup");
    const utm_medium = localStorage.getItem("utm_medium");
    const utm_source = localStorage.getItem("utm_source");
    const utm_campaign = localStorage.getItem("utm_campaign");
    const gclid = JSON.parse(localStorage.getItem("gclid"));
    const session_start_page = getCookie("session_start_page");

    const {
      firstname: { value: first_name },
      lastname: { value: last_name },
      email: { value: email },
      phone: { value: phone_number },
      numberofemployees2: { value: employee_count },
    } = formState;

    const schema = {
      created_at,
      utm_keyword,
      utm_adgroup,
      utm_medium,
      utm_source,
      utm_campaign,
      gclid: !!gclid ? gclid.value : "",
      first_page_seen: document.referrer,
      first_name,
      last_name,
      email,
      phone_number,
      employee_count,
      cta_page: window.location.href,
      session_start_page,
    };

    sendPartialLeadToBigQuery(schema);
  };

  const handleValidate = () => {
    if (formRef.current) {
      const isValid = formRef.current.reportValidity();
      if (isValid) {
        handleFirstSubmit();
      } else {
        return;
      }
    }
  };

  const handleFirstSubmit = async () => {
    setLoading(true);
    const {
      firstname: { value: firstName },
      lastname: { value: lastName },
      email: { value: emailAddress },
      phone: { value: phone },
    } = formState;

    const matchPersonInput = {
      firstName,
      lastName,
      phone,
      emailAddress,
    };

    getZiContact(matchPersonInput).then(data => {
      setLoading(false);
      const matchStatus = data.data.result[0].matchStatus;
      const matchedFields = data.data.result[0].data[0];
      const outputFields = getSecondFormFields(matchedFields);
      if (
        matchStatus === "FULL_MATCH" &&
        (!!matchedFields.region || !!matchedFields.company.state)
      ) {
        const populatedState = handlePopulateStateFromZIResponse(matchedFields);
        handleFinalSubmit(null, populatedState);
      } else {
        setShowFinalStep(true);
        setZiOutputFields(outputFields);
        handlePopulateStateFromZIResponse(matchedFields);
        handleSendDataToBigQuery();
      }
    });
  };

  const getSecondFormFields = matchedFields => {
    const fullSecondFormFields = ["company", "jobtitle", "country", "state"];
    if (!matchedFields) return fullSecondFormFields;

    const companyFields = matchedFields.company || {};
    const outputFields = Object.keys(matchedFields).reduce((acc, key) => {
      if (key === "company") {
        Object.entries(companyFields).forEach(([fieldKey, fieldValue]) => {
          if (!fieldValue) {
            if (fieldKey === "name") acc.push("company");
            else if (fieldKey === "country") acc.push("country");
            else if (fieldKey === "state") acc.push("state");
          }
        });
      } else if (!companyFields[key] && !matchedFields[key]) {
        acc.push(key.toLowerCase());
      }
      return acc;
    }, []);

    return outputFields;
  };

  const getSafeKeyForZI = key => {
    if (key === "jobtitle") {
      return "jobTitle";
    }
    return key;
  };

  const getSafeKeyForHubspot = key => {
    if (key === "region") {
      return "state";
    }
    return key;
  };

  const handlePopulateStateFromZIResponse = matchedFields => {
    if (!matchedFields) return;
    const keysToUpdate = Object.keys(matchedFields)
      .filter(key => !!matchedFields[key])
      .map(key => key.toLowerCase());
    let populatedState = { ...formState };

    keysToUpdate.forEach(key => {
      if (key === "id") return;

      if (key === "company") {
        populatedState = {
          ...populatedState,
          company: {
            objectTypeId: "0-1",
            value: matchedFields[key].name,
          },
          country: {
            objectTypeId: "0-1",
            value: matchedFields[key].country,
          },
          state: {
            objectTypeId: "0-1",
            value: matchedFields[key].state,
          },
        };
      } else {
        const valueToSend = matchedFields[getSafeKeyForZI(key)];

        const safeKeyToIndexFormState = getSafeKeyForHubspot(key);
        populatedState = !!valueToSend
          ? {
              ...populatedState,
              [safeKeyToIndexFormState]: {
                objectTypeId: "0-1",
                value: valueToSend,
              },
            }
          : { ...populatedState };
      }
      setFormState(populatedState);
    });
    return populatedState;
  };

  const errorFound = useMemo(() => {
    if (!validationState) return false;
    return Object.keys(validationState).some(
      key => validationState[key].valid === false
    );
  }, [validationState]);

  const handleFinalSubmit = (event, state) => {
    event && event.preventDefault();
    const stateToSend = state ?? formState;
    setSending(true);
    const pageUri = window && window.location.href;
    if (errorFound) {
      setSending(false);
      return;
    }
    submitForm(formId, stateToSend, pageUri, document.title, null)
      .then(data => {
        if (!data) return;
        setSubmitted(true);
        if (posthog) {
          const currentUrl = window.location.href;
          posthog.capture("full_form_complete", "$pageview", {
            $current_url: currentUrl,
          });
        }
        sendCustomGtagEvent("full_form_complete");
        setSending(false);
        setOnFormCompleteBoolean && setOnFormCompleteBoolean(true);
      })
      .catch(error => {
        console.error(error);
        setSending(false);
      });
  };

  if (loading || sending)
    return (
      <FormWrapper maxWidth={maxFormWidth}>
        <TextContainer>
          <FormTitle fancy={useFancyTitle}>
            {!showFinalStep ? title : lastStepTitle}
          </FormTitle>
          <MainParagraph>{subTitle}</MainParagraph>
        </TextContainer>
        <FormBackground show={useFormBackground}>
          <FormLoader
            fieldCount={sending ? fieldsToRender.length + 1 : 6}
          ></FormLoader>
        </FormBackground>
      </FormWrapper>
    );

  if (submitted) {
    return (
      <FormWrapper maxWidth={maxFormWidth}>
        <FormBackground show={useFormBackground}>
          <TextContainer>
            <FormTitle fancy={useFancyTitle}>{successTitle}</FormTitle>
            <MainParagraph>{successMessage}</MainParagraph>
          </TextContainer>
        </FormBackground>
      </FormWrapper>
    );
  }

  return (
    <FormWrapper maxWidth={maxFormWidth}>
      <TextContainer>
        <FormTitle fancy={useFancyTitle}>
          {!showFinalStep ? title : lastStepTitle}
        </FormTitle>
        <MainParagraph>{subTitle}</MainParagraph>
      </TextContainer>
      {showFinalStep && <p>{lastStepText}</p>}
      <FormBackground show={useFormBackground}>
        <Form onSubmit={handleFinalSubmit} onFocus={handleFocus} ref={formRef}>
          {fieldsToRender.map(renderFields)}
          <ButtonComponent
            data-testid="submit-button"
            as={"button"}
            style={{ width: "100%" }}
            type={!showFinalStep ? "button" : "submit"}
            onClick={!showFinalStep && handleValidate}
          >
            Submit
          </ButtonComponent>
        </Form>
        {renderDisclaimer?.()}
      </FormBackground>
    </FormWrapper>
  );
};
