import { ZoomInfoForm } from "components/form/zoomInfo/ZoomInfoForm";
import GlobalLayout from "components/page/GlobalLayout";
import { TextContainer } from "components/rhombus-UI/theme/containers";
import { MainParagraph } from "components/rhombus-UI/theme/typography";
import { styled } from "linaria/react";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import LogoBlade from "../consultant-lp/LogoBlade";
import image from "./assets/cameras-1.png";
import imageMobile from "./assets/cameras-mobile.png";
import { Link } from "gatsby";
import { CTA_4_KEYWORD_COPY } from "components/data/landing-page-data";

const PageContainer = styled.section`
  height: calc(100vh - 80px);
  min-height: 800px;
  padding-top: 2.5rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: var(--gradient-light);
  gap: 2.5rem;
  position: relative;
  ::after {
    content: "";
    position: absolute;
    width: 845px;
    height: 419px;
    right: 0;
    bottom: 100px;
    z-index: 0;
    background-image: url(${image});
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    @media (max-width: 820px) {
      display: none;
    }
  }
  @media (max-width: 1024px) {
    height: fit-content;
    min-height: 100vh;
  }
  @media (max-width: 820px) {
    gap: 0;
  }
`;

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.25rem;
  z-index: 1;
  flex-grow: 1;
  position: relative;
  ::after {
    position: absolute;
    width: 100%;
    height: 298px;
    right: 0;
    bottom: -2rem;
    z-index: -1;
    background-image: url(${imageMobile});
    background-position: bottom right;
    background-size: contain;
    background-repeat: no-repeat;
    @media (max-width: 820px) {
      content: "";
    }
  }
  @media (max-width: 820px) {
    padding-bottom: 5rem;
  }
`;

const backLink = "/lp/cameras-c/";

const CTAPage2 = () => {
  const [headline, setHeadline] = useState("");
  useEffect(() => {
    const defaultHeadline =
      "Take the next step— Start a risk-free trial with Rhombus' Cameras. Install and start viewing footage from any device in seconds";
    const keyWord = localStorage.getItem("utm_keyword");
    setHeadline(CTA_4_KEYWORD_COPY[keyWord] ?? defaultHeadline);
  }, []);
  return (
    <GlobalLayout
      landingPage
      formOnly
      color="var(--gradient-light)"
      logoLink={backLink}
      useFullWidth
      useIncreasedPadding
      useFullWidthLogo
      diableStickyHeader
    >
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <PageContainer>
        <FormContainer>
          <ZoomInfoForm
            useFormBackground
            useButtonv2
            maxFormWidth="591px"
            useFancyTitle
            formId={"30d2b33c-32af-4a24-8fc7-5a6fe7ee9b15"}
            title="Try Rhombus in a Risk Free Trial"
            lastStepTitle="Try Rhombus in a Risk Free Trial"
            subTitle={headline}
            renderDisclaimer={() => (
              <TextContainer style={{ textAlign: "center" }} width="544px">
                <MainParagraph style={{ fontSize: "12px" }}>
                  *
                  <Link
                    href="https://legal.rhombus.com/legal/product-evaluation-agreement"
                    target="_blank"
                    style={{
                      textDecoration: "underline",
                      color: "var(--blue-900)",
                    }}
                  >
                    Trial terms apply.
                  </Link>
                  The Rhombus team decides equipment allocation & duration. Some
                  larger trials may require a deposit. Rhombus does not charge
                  for any products you return at the end of the trial period."
                </MainParagraph>
              </TextContainer>
            )}
          />
        </FormContainer>
        <LogoBlade
          style={{
            paddingTop: "2.5rem",
            paddingBottom: "2.5rem",
            background: "var(--white)",
            alignSelf: "stretch",
            zIndex: 1,
          }}
        />
      </PageContainer>
    </GlobalLayout>
  );
};

export default CTAPage2;
