export const getZiContact = async matchPersonInput => {
  return fetch(`/api/zoomInfo/getContact`, {
    method: "POST",
    body: JSON.stringify({
      outputFields: [
        "companyName",
        "jobTitle",
        "country",
        "region",
        "companyCountry",
        "companyState",
      ],
      matchPersonInput: [matchPersonInput],
    }),
  })
    .then(res => res.json())
    .then(data => {
      return data;
    })
    .catch(err => {
      console.error(err);
    });
};

export const sendPartialLeadToBigQuery = schema => {
  return fetch("/api/bigQuery/sendPartialLead", {
    method: "POST",
    body: JSON.stringify(schema),
  });
};
